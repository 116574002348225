

<script>

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

import {searchAgent, searchRegbvAgent} from '@/api/misc'



export default {


    emits: ['onSelected','onCleared'],

    props: {
        init_data : {
            type : Object,
            default: () => ({
                agent_name: '',
                agent_id : '',
                style_class : {},
                placeholder : '',
                user_obj    : null,
            })
        },

        search_type : {
            type : String,
            default : () => 'LOCAL'
        },

        show_agent_code :  {
            type : Boolean,
            default : () => false
        },

        show_agent_vin :  {
            type : Boolean,
            default : () => true
        },

        show_brokerage : {
            type : Boolean,
            default : () => true
        },

        readonly : {
            type : Boolean,
            default : () => false
        },

        

    },

    data() {
        return {
            agent_name: '',
            agent_id : '',
            searched_data : []
        }
        
    },

    methods: {
        onSearched(new_value) {

            if (new_value == '' || typeof new_value == 'undefined') {
                this.$emit('onCleared')
                this.$emit('input', new_value)
                return
            }
            
            if (this.search_type =='REGBV') {

                searchRegbvAgent({str : new_value}).then(res => {
                    this.searched_data = []
                    res.data.map((d) => {
                        this.searched_data.push({
                            label: `${d.first_name}  ${d.last_name}` +  (this.show_brokerage? `(${d.brokerage_name})` :''),
                            value: d.vin,
                            agent: d
                        })
                    })
                    if (this.searched_data.length <= 0) {
                        this.$emit('input', new_value)
                    }
                })
            } else {
                searchAgent({str : new_value}).then(res => {
                    this.searched_data = []
                    res.data.map((d) => {
                        this.searched_data.push({
                            label: `${d.first_name}  ${d.last_name} ${this.show_agent_vin? '('+d.code+')':''}  ${this.show_agent_code? '('+d.LWA_userName+')':''} `,
                            value: d.id,
                            agent: d
                        })
                    })
                })

            }
            
        },

        onHit(evt) {
            evt.user_obj = this.init_data.user_obj
            this.$refs['vbt'].inputValue = evt.label
            this.$emit('input', evt.label)
            this.$emit('onSelected', evt, this)
          //  this.$emit('input', this.agent_name)
        },


        update_input_agent_name(val) {
            this.$refs['vbt'].inputValue = val
        },
        
    },

    created() {
       
    },

    mounted() {
        if (this.init_data && this.init_data.agent_name) {
            this.$refs['vbt'].inputValue = this.init_data.agent_name
        }

    },

    components: {
        VueBootstrapTypeahead
    },

    watch: {
        agent_name: function(new_value) { 
            this.onSearched(new_value)
        },
       
    },

   


}
</script>

<template>
     <vue-bootstrap-typeahead 
        ref="vbt"
        v-model="agent_name"
        :data="searched_data"
        :inputClass="init_data.style_class"
        :serializer="s => s.label"
        :foramterDisplay="s => s.label"
        :placeholder ="init_data.placeholder"
        :remoteMatch = true
        :disabled="readonly"
        @hit="onHit($event)"
        autocomplete="off"
        />
</template>