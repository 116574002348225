

import request from '@/utils/request';


class TaxApi {

    nr4_slip_preview = (data) => {
        return request({
            url: '/adm/accounting/tax/query_owner_nr4',
            method: 'post',
            data
        });
    }

    submit_nr4 = (data) => {
        
        return request({
            url: '/adm/accounting/tax/submit_and_export_nr4',
            method: 'post',
            data
        });
    }

    nr_list = (data) => {
        return request({
            url: '/adm/accounting/tax/nr_list',
            method: 'post',
            data
        });
    }


    agent_t4a_list= (data) => {
        return request({
            url: '/adm/accounting/tax/agent_t4a_list',
            method: 'post',
            data
        });
    }

    agent_t4a_break_down_pdf = (data) => {
        return request({
            url: '/adm/accounting/tax/agent_gst_form',
            method: 'post',
            data
        });
    }

    query_agent_gst = (data) => {
        return request({
            url: '/adm/accounting/tax/agent_gst',
            method: 'post', 
            data
        });	
    }

    t4a_slip_preview = (data) => {
        return request({
            url: '/adm/accounting/tax/agent_t4a_pdf',
            method: 'post', 
            data
        });	
        
    }

}


let _tax_api = null

const getTaxApi = () => {
    if (!_tax_api) {
        _tax_api = new TaxApi();
    }
    return _tax_api;
}

export {getTaxApi};
