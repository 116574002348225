<script>
//import { required } from "vuelidate/lib/validators";

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {getTaxApi} from '@/api/accounting/tax'
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import AgentAutoComplete from '@/components/agent-auto-complete-input'
import { financial } from '@/api/misc'

/**
 * User list component
 */
export default {
  page: {
    title: "Agent Tax Slip",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      listingData: [],
      expenseList: [],
      title: "Agent Tax Slip",
      items: [
        {
          text: "Accounting",
          href: "/",
        },
        {
          text: "General Ledger",
          active: true,
        },
      ],
      totalRows: 0,
      currentPage: 1,
      submitted: false,
      searched_str : '',
      start_date : '',
      end_date   : '',
      agent_name : '233',
      agent_id   : '',
     
      upload_gl_entries: []
    };
  },
  components: {
    Layout,
    PageHeader,
    flatPickr,
    AgentAutoComplete
  },
  computed: {
   
  },

  created() {
    
  },
  mounted() {
   
    if (this.$route.query) {
      this.agent_name = this.$route.query.agent_name
      this.agent_id   = this.$route.query.agent_id
      this.start_date = this.$route.query.start_date
      this.end_date   = this.$route.query.end_date
      this.queryList()
    }
  },
  methods: {

    f(v, d = 2) {
      return financial(v,d)
    },


    queryList() {

    
      let data = {
        agent_id : this.agent_id,
        start_date : this.start_date,
        end_date   : this.end_date,
      
      }
      getTaxApi().query_agent_gst(data).then(res => {
        if (res.errCode == 0) {
          this.listingData = []
          this.listingData = res.data.commission
          this.expenseList = res.data.expenses
        }
      })
    },

    onSelectedAgent(agent) {
      this.agent_id = agent.agent.id
    //  this.agent_name = agent.first_name + ' '+agent.last_name
    },

    export_detail() {

      let data = {
        agent_id : this.agent_id,
        start_date : this.start_date,
        end_date   : this.end_date,
      
      }
      getTaxApi().agent_t4a_break_down_pdf(data).then(res => {
        if (res.errCode == 0) {
          window.open(res.url, '_blank')
        }
      })
      
    },

    export_t4a() {
      let data = {
        agent_id : this.agent_id,
        start_date : this.start_date,
        end_date   : this.end_date,
      
      }
      getTaxApi().t4a_slip_preview(data).then(res => {
        if (res.errCode == 0) {
          window.open(res.url, '_blank')
        }
      })

      
    }
   

  },
  validations: {
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-md-2">
                <label>Agent </label>
                <AgentAutoComplete  v-model="agent_name"   @onSelected="onSelectedAgent"   autocomplete="off" :init_data="{agent_name : agent_name}" />
              </div>

              <div class="col-md-2">
                <label>Start Date </label>
                <flat-pickr  v-model="start_date"  placeholder="Select a date"  class="form-control"  ></flat-pickr>
              </div>
              <div class="col-md-2">
                <label>End Date </label>
                <flat-pickr  v-model="end_date"  placeholder="Select a date"  class="form-control"  ></flat-pickr>
              </div>
              <div class="col-md-6 gap-2 mb-3 gap-2 mt-4">
                <div
                  class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3"
                >
                <b-button variant="primary" @click="queryList">Query</b-button>
                <b-button variant="primary" @click="export_detail">Export Break Down Pdf</b-button>
                <b-button variant="primary" @click="export_t4a">T4A</b-button>
                
                </div>
              </div>

              
            </div>
            <!-- end row -->

            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table
                  :items="listingData"
                  :fields="['Date', 'Description', 'Incoming', 'Deductible', 'Gstiable','Gst']"
                  responsive="sm"
                  :per-page="listingData.length"
                  class="table-check"
                
                >
                  <template #cell(Date)="data"> 
                    {{ data.item.date}}
                  </template>
                  <template #cell(Description)="data"> 
                    {{ data.item.description}}
                  </template>
                  <template #cell(Incoming)="data">
                    ${{ f(data.item.incoming).toLocaleString()}}
                  </template>
                  <template #cell(Deductible)="data">
                    ${{ f(data.item.deductible).toLocaleString()}}
                  </template>
                  <template #cell(Gstiable)="data">
                    ${{ f(data.item.incoming - data.item.deductible).toLocaleString() }}
                  </template>
                  <template #cell(Gst)="data">
                    ${{ f(data.item.net_gst).toLocaleString() }}
                  </template>
                </b-table>
              </div>
            </div>
            <!-- end table responsive -->


            <div class="table-responsive pt-4">
              <div class="table align-middle table-nowrap table-borderless">
                <b-table
                  :items="expenseList"
                  :fields="['ExpenseCode','ExpenseName',   'Amount', 'Gst', 'Total']"
                  responsive="sm"
                  :per-page="expenseList.length"
                  class="table-check"
                >
                  <template #cell(ExpenseName)="data"> 
                    {{ data.item.fee_name}}
                  </template>
                  <template #cell(ExpenseCode)="data">
                    {{ data.item.fee_code}}
                  </template>
                  <template #cell(Space)>
                  </template>
                  <template #cell(Amount)="data">
                    {{ data.item.amount}}
                  </template>
                  <template #cell(Gst)="data">
                    {{ data.item.gst }}
                  </template>
                  <template #cell(Total)="data">
                    {{ data.item.amount + data.item.gst }}
                  </template>
                </b-table>
              </div>
            </div>
            <!-- end table responsive -->



          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
